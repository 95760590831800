var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "header header__gradient",
      class: _vm.backgroundGradient,
      style: _vm.backgroundImgLink,
    },
    [
      _c("div", { staticClass: "container container--sm" }, [
        _c("h1", [_vm._v(_vm._s(_vm.heading))]),
        _c("p", { staticClass: "p--lead" }, [_vm._v(_vm._s(_vm.subheading))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }