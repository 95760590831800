import { get } from '~/services/http-requests'
import { randomizeList } from '~/utils/randomizeList'

const _BS_INSTRUMENTS = 'instruments'

export async function fetchSingleInstrument(instrumentSlug: IInstrument['slug']): Promise<IInstrument> {
    const [first] = await get({ baseProperty: _BS_INSTRUMENTS, fullUrl: `${_BS_INSTRUMENTS}.json?slug=${instrumentSlug}` })
    return first
}

export const fetchAllInstruments = async (): Promise<IInstrument[]> => randomizeList(await get({ baseProperty: _BS_INSTRUMENTS }))
