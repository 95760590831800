











import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import HeaderSection from '@/components/HeaderSection.vue'
import { fetchAllInstruments } from '../api/instrument'

@Component({ components: { HeaderSection } })
export default class InstrumentsPage extends Vue {
    instruments: IInstrument[] = []

    async mounted() {
        this.instruments = await fetchAllInstruments()
    }
}
